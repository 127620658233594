var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"slider_wrapper"},[_c('a',{staticClass:"slider_arr_left",on:{"click":function($event){$event.preventDefault();return _vm.prevSlide($event)}}},[_c('Icon',{attrs:{"xlink":"sliderArrLeft","viewport":"0 0 12 32"}})],1),_c('a',{staticClass:"slider_arr_right",on:{"click":function($event){$event.preventDefault();return _vm.nextSlide($event)}}},[_c('Icon',{attrs:{"xlink":"sliderArrRight","viewport":"0 0 12 32"}})],1),_c('div',{ref:"slider",staticClass:"slider js-slider"},[_c('div',{staticClass:"slider__body",style:({ left: _vm.sliderOffsetLeft + 'px' })},_vm._l((_vm.splittedArray),function(block,idx){return _c('div',{key:idx,ref:"slide",refInFor:true,staticClass:"slider__slide js-slide"},_vm._l((block),function(item,idx){return _c('div',{key:idx,staticClass:"item"},[(!item.isAddBtn)?[_c('div',{staticClass:"item_img",style:('background-image: url(' +
                  ((item.cover && item.cover.url) || _vm.testImg) +
                  ')')},[_c('div',{staticClass:"item_edit",on:{"click":function($event){return _vm.$router.push({
                    name: _vm.editRouteName,
                    params: {
                      target: 'edit',
                      id: item.id,
                      type: item.type.id,
                    },
                  })}}},[_c('Icon',{attrs:{"xlink":"edit","viewport":"0 0 16 16"}})],1),_c('div',{staticClass:"item_ribbon",style:(("background: " + _vm.ribbonColor))},[_vm._v(" "+_vm._s(_vm.ribbonLabel)+" ")])]),_c('div',{staticClass:"item_label"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('div',{staticClass:"item_date"},[_vm._v(" "+_vm._s(_vm._f("date")(item.published_at))+" ")])]:[_c('div',{staticClass:"add_item_icon",on:{"click":function($event){return _vm.$router.push({ name: _vm.routeName })}}},[_c('Icon',{attrs:{"xlink":"add","viewport":"0 0 48 48"}})],1),_c('div',{staticClass:"add_item_text",on:{"click":function($event){return _vm.$router.push({ name: _vm.routeName })}}},[_vm._v(" "+_vm._s(_vm.addBtnLabel)+" ")])]],2)}),0)}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }